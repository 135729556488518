
.Scenes ul {
  height: 40vh;
  padding: 1rem 0;
  margin: 0 1rem;
  display: flex;
}

.Scenes ul li {
  flex-grow: 0;
  flex-shrink: 0;
  border: 1px solid grey;
  border-radius: 1rem;
  margin: 0 0.5rem;
  height: 97%; /* more than that and it can be scrolled vertically */
  position: relative;
  overflow: hidden;
}

.Scenes ul li.active {
  border: 2px solid white;
}

.Scenes ul li figure {
  height: 100%;
  width: 100%;
  display: inline-block;
}
.Scenes ul li figure img {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.Scenes .backgroundVolume {
  margin-top: 1rem;
}
.Scenes .backgroundVolume .icon {
  margin: 0 1rem;
}


.More:not(.iconContainer) {
  width: 100%;
  padding-top: calc(2rem + env(safe-area-inset-top));
}

.More li {
  width: 100%;
  border-bottom: 1px solid #4E5259;
}
.More li:last-of-type {
  border-bottom: none;
}
.More li a {
  display: flex;
  width: 100%;
  align-items: center;
}
.More li .iconContainer {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.5rem 1rem;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.More li .iconContainer.Breathe {
  border-color: #f4b86a;
}
.More li .iconContainer.Scenes {
  border-color: #86f080;
}
.More li .iconContainer.Settings {
  border-color: #75B2F0;
}
.More li .iconContainer.Downloads {
  border-color: #f68c7a;
}
.More li .iconContainer.About {
  border-color: #6ad2c5;
}

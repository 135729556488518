
.Downloads .PageWithTitle dt {
    cursor: pointer;
}

.Downloads .PageWithTitle .icon {
    margin-right: 1rem;
}

.Downloads .PageWithTitle .icon > span {
    border: none !important;
}
.Downloads .PageWithTitle .text {
    padding: 1rem;
    font-size: smaller;
    text-align: center;
}

.Downloads .PageWithTitle dt:last-of-type, .Downloads .PageWithTitle dd:last-of-type {
    margin-top: 1rem;
}

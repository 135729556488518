

.BackgroundScene {
    height: 100%;
    overflow: hidden;
    display: flex;
}
.BackgroundScene, .BackgroundScene img, .Scenes .BackgroundScene video {
  width: 100%;
  align-self: center;
}

.Scenes .BackgroundScene video, .Home .BackgroundScene video {
  height: 100%;
  width: 100%;
  object-fit: cover; /* maintain aspect ratio */

  /* set position static otherwise it's overwritten by fixed and video is
    streched to the right corner in desktop browser */
  position: static;
}

.Home .BackgroundScene img.stretched {
  height: 100vh;
}
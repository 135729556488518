.Program {
  flex-direction: column; }

.Program header {
  padding: calc(env(safe-area-inset-top) + 1rem) 1rem 0.5rem 1rem;
  background-color: #201625;
  font-weight: lighter; }

.Program header i {
  font-size: xx-large;
  cursor: pointer; }

.Program header h2 {
  margin-bottom: 1rem;
  text-align: center; }

.Program header figure {
  float: left;
  margin-right: 1rem;
  width: 15%;
  padding-top: 15%;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  /* the following line fixes Safari bug for overflow hidden */
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  display: block; }

.Program header figure img {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

.Program .description-and-asset-list {
  overflow-y: scroll;
  max-height: calc(100vh - 10rem);
  width: 100%;
  padding-top: 1rem; }

.Program .description {
  text-align: center;
  width: 100%; }

/*#8b8487;*/
.Program .horizontal-line {
  border-bottom: 2px solid grey;
  margin: 1rem 1.5rem; }

.Program ol {
  width: 100%;
  padding-bottom: 8rem;
  color: grey; }

.Program ol li {
  display: block;
  text-align: center;
  padding: 2rem;
  position: relative; }

.Program ol li .orderNumber {
  left: 1.5rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 1.75rem;
  border: 1px solid white; }

.Program ol li .title-container {
  position: relative;
  left: 2rem;
  width: calc(100% - 1.5rem); }

.Program ol li .title {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  /* for remove highlight on android */
  width: 100%; }

.Program ol li .title > * {
  pointer-events: none; }

.Program ol li .likeIcon {
  right: 1.5rem;
  cursor: pointer;
  display: none; }

.Program ol li .likeIcon i.fas.fa-thumbs-up {
  color: rgba(40, 226, 253, 0.86); }


.MediaControl {
    display: flex;
    flex-direction: column;
    margin: 1rem 3rem 0.5rem 3rem;
}
.MediaControl.minimize {
    margin: 0;
    width: 6rem;
    flex-direction: row-reverse;
}
.MediaControl .MediaControlButtons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 2rem;
}
.MediaControl i {
    text-align: center;
    font-size: x-large;
    width: 4rem;
    position: relative;
    cursor: pointer;
}
.MediaControl i span {
    position: absolute;
    display: inline-block;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: xx-small;
}
.MediaControl i.fa-redo span {
    margin-left: -3px;
}
.MediaControl i.fa-redo undo {
    margin-right: -3px;
}
.MediaControl .fa-play {
    font-size: xx-large;
}
.MediaControl.minimize .fa-play {
    font-size: x-large;
}
.MediaControl .fa-pause {
    font-size: xx-large;
}

.MediaControl .MediaControlProgressBar {
    z-index: 0;
}
.MediaControl progress {
    margin: 1rem 0 0rem 0;
    box-sizing: content-box;
    height: 3px;
    background-color: black; /* Firefox */

    width: 100%;
    border: 10px solid transparent;
    border-left: none;
    border-right: none;
    background-clip: padding-box;

    position: relative; /* otherwise it's covered by background image */
}
.MediaControl.minimize progress {
    margin: 0;
    background-color: #919692;
    border: none;
}
/* Progress Bar Value color */
progress::-webkit-progress-value /* Chrome, Safari */ {
    background: #3bbeec;
}
progress::-moz-progress-bar /* Firefox */ {
    background: #3bbeec;
}
/* Progress Bar Value background color */
progress::-webkit-progress-bar /* Chrome, Safari */ {
    background: black;
}

.MediaControl.minimize .MediaControlProgressBar {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
}
.MediaControl .MediaControlProgressBar footer {
    height: 1.5rem;
    text-align: left;
}
.MediaControl.minimize .MediaControlProgressBar footer {
    height: 1.5rem;
    text-align: center;
}
.MediaControl .MediaControlProgressBar #MaxTime,
.MediaControl .MediaControlProgressBar #CurrentTime {
    font-size: smaller;
}
.MediaControl .MediaControlProgressBar #MaxTime {
    float: right;
}
.MediaControl.minimize .MediaControlProgressBar #MaxTime {
    display: none;
}
.MediaControl .MediaControlProgressBar #progressBarCircle {
    visibility: hidden;
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: #3bbeec;
    background-clip: padding-box;
    border: 2px solid rgba(59, 190, 236, .5); /*#3bbeec;*/
    border-radius: 6px;
}


* {
  margin: 0;
  padding: 0;
  /* for smooth scrolling on Safair */
  -webkit-overflow-scrolling: touch;
  /*overflow-y: scroll;*/
  /* has to be scroll, not auto */
  -webkit-tap-highlight-color: transparent;
  /* for remove highlight on android */
  user-select: none;
  /* disable selecting text */ }

html, body, #root, .App {
  min-height: 100%;
  color: white;
  background-color: black; }

html {
  position: fixed;
  /* to prevent bouncing effect after pulling on iPhone */
  width: 100%;
  /* to center body tag on desktop */
  left: 0;
  right: 0; }

html, body {
  height: 100%;
  overflow-y: hidden;
  /* to prevent pull-to-refresh effect on Android */ }

#root {
  display: flex;
  font-family: 'Nunito Sans', sans-serif; }

@media only screen and (min-width: 576px) {
  #root {
    width: 360px;
    margin: auto;
    border: 1px solid white;
    border-top: none;
    border-bottom: none; }
  .App .NavBar,
  .App .PageWithTopNavBar > header,
  .App .PageWithTopNavBar > section,
  .App .Asset,
  .App .background,
  .App .PageWithTopNavBar .error {
    width: 360px; } }

.App {
  display: flex;
  width: 100%;
  overflow: hidden; }

.App > .Content {
  /*height: 100vh;*/
  /* on iPhone 100vh includes system bar/address bar */ }

span:lang(ar) {
  font-size: 175%;
  line-height: 175%; }

/* ---------------------------------------------------------------------- */
.Home, .Music, .More {
  width: 100%;
  display: flex;
  flex-direction: row; }

.Home {
  height: 100vh;
  justify-content: center; }

/* ---------------------------------------------------------------------- */
/* should be aligned with numbers in Program asset list */
.NavBar ul {
  padding: 0 1.05rem;
  justify-content: space-between;
  display: flex; }

/* ---------------------------------------------------------------------- */
.Content {
  /*display: flex;*/
  /* otherwise on Safari thumbnails are below bottom */
  width: 100%; }

.Content > div {
  display: flex;
  /* make it stretch to the bottom */
  width: 100%; }

h2 {
  font-size: 1.25rem;
  font-weight: 300; }

.TextRectangleArea {
  padding-top: 1rem; }

.TextRectangleItem {
  background-color: #E6E6E6;
  margin: 0 1rem 1rem 1rem;
  text-align: center;
  border-radius: 1rem; }

.TextRectangleItem .positioning {
  padding: 2rem;
  color: #5C5C5C; }

.PageWithTitle {
  width: 100%; }

.PageWithTitle header {
  text-align: center;
  padding: calc(1rem + env(safe-area-inset-top)) 0 1rem 0; }

/* ---------------------------------- */
.Video video {
  height: calc(100% - 6rem);
  width: 100%; }

/* ---------------------------------- */
.Sleep video::-webkit-media-controls-panel {
  display: flex !important;
  opacity: 1 !important; }

/* ---------------------------------- */
.PageWithTitle dl {
  padding: 1rem 2rem 1rem 1rem; }

.PageWithTitle dt, .PageWithTitle dd {
  display: inline-block;
  margin-bottom: 1rem; }

.PageWithTitle dd {
  float: right; }

/* ---------------------------------- */
.Scenes ul li {
  flex-basis: calc(45vw - 1rem); }

@media only screen and (min-width: 576px) {
  .Scenes ul li {
    /*width: calc(360px / 2 - 1rem);*/
    flex-basis: calc(360px / 2 - 1rem); } }

/* ---------------------------------------------------------------------- */
.horizontal-scrolling-menu {
  /* ul element */
  /* from https://iamsteve.me/blog/entry/horizontal-scrolling-responsive-menu */
  /*  margin-top: 1rem;*/
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  /* Make it smooth scrolling on iOS devices */
  /*-ms-overflow-style: -ms-autohiding-scrollbar; /* Hide the ugly scrollbars in Edge until the scrollable area is hovered */
  srollbar-width: none;
  /*overflow: -moz-scrollbars-none;*/ }

.horizontal-scrolling-menu::-webkit-scrollbar {
  display: none;
  /* Hide the scroll bar in WebKit browsers */ }

figure figcaption.bottomTitle {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  color: white;
  font-size: 80%;
  text-align: center; }

.backgroundColorBlack {
  background-color: black; }

.hide {
  display: none !important; }

.vertically-centered {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.fixed-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.float-left {
  float: left; }

.float-right {
  float: right; }

.left {
  left: 0;
  position: absolute; }

.right {
  right: 0;
  position: absolute; }

ul {
  padding: 0;
  margin: 0; }

li {
  display: inline-block; }

a {
  color: white;
  text-decoration: none; }

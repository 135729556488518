
.NavBar {
  position: fixed;
  bottom: 0;
  padding: 1rem 0 0.8rem 0;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.NavBar li {
  font-size: 80%;
  color: grey;
  text-align: center;
}

.NavBar a.active li {
  color: white;
}

.NavBar li .iconContainer {
  width: 2.5rem;
  height: 2.5rem;
  margin: auto;
  display: flex;
}

.NavBar a.active .iconContainer .nonactive,
.NavBar a .iconContainer .active {
  display: none;
}

.NavBar a.active .iconContainer .active,
.NavBar a.active .iconContainer .active * {
  display: flex;
  justify-content: center;
  width: 100%;
}

.NavBar .iconContainer .active,
.NavBar .iconContainer .nonactive,
.NavBar .iconContainer .nonactive * {
  width: 100%;
  display: flex;
  justify-content: center;
}

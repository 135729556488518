
.About {
    text-align: center;
}
.About .PageWithTitle {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: calc(100vh - 9rem);
}
.About img {
    width: 60%;
    margin: 0 auto;
}
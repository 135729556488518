.PageWithTopNavBar {
  width: 100%;
  height: 100%;
  /* otherwise scrolling doesn't work */
  padding-top: calc(5.2rem + env(safe-area-inset-top));
  z-index: 0; }

.PageWithTopNavBar > header {
  background-color: inherit;
  text-align: center;
  position: fixed;
  top: 0rem;
  width: 100%;
  padding: env(safe-area-inset-top) 0 0.5rem 0;
  margin: auto;
  /* for centering on desktop */ }

.PageWithTopNavBar header ul {
  margin-top: 1rem; }

.PageWithTopNavBar header ul li {
  margin: 0 0.5rem; }

.PageWithTopNavBar header ul a {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-width: 1px;
  border-radius: 1rem; }

.PageWithTopNavBar header h2 {
  font-weight: 540; }

.PageWithTopNavBar section {
  width: 100%;
  position: fixed; }

/*.PageWithTopNavBar .background,*/
.background {
  position: fixed;
  height: 100vh;
  width: 100%;
  object-fit: cover;
  /*z-index: 0;*/
  border-radius: 0.1px;
  /* to fix bug in Chrome that show video wider than specified width */
  background-image: linear-gradient(#515858, #b0b8bc, #9db1b9); }

.PageWithTopNavBar .error {
  text-align: center; }

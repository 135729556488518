.Asset + .Content {
  overflow-y: hidden; }

.Asset.minimize + .Content {
  overflow-y: auto; }

.Asset {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  /* to hide bottom navigation icons (round) */
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.Asset.text .background {
  background-color: #515858;
  background-image: none; }

.Asset.minimize {
  top: auto;
  bottom: 5rem;
  height: 3.5rem;
  border: 1px solid white;
  border-radius: 4rem;
  margin: 0 1rem;
  width: calc(360px - 2rem);
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  /* for remove highlight on android */ }

/*.Asset.minimize .BackgroundScene,*/
.Asset.minimize .background,
.Asset.minimize .fa-chevron-down,
.Asset.minimize > header > .downloadIcon,
.Asset.minimize .descriptionSection section,
.Asset.minimize .descriptionSection footer dt,
.Asset.minimize #asset-error,
.Asset.minimize span.likeIcon, .Asset.minimize span.shareIcon,
.Asset.minimize .fa-undo, .Asset.minimize .fa-redo, .Asset.minimize .fa-stop,
.MediaControl,
.Asset.minimize .MediaControlProgressBar #progressBarCircle {
  display: none; }

.Asset > header {
  padding-top: calc( env(safe-area-inset-top) - 1rem);
  text-align: center;
  z-index: 1; }

.Asset > header > span {
  margin: 1rem;
  font-size: x-large;
  cursor: pointer;
  position: absolute; }

.Asset > header > .minimizeIcon, .Asset > header > .backIcon {
  left: 0; }

.Asset > header > .downloadIcon {
  right: 0; }

.Asset > header > .downloadIcon i.fa-cloud {
  color: #66b3f9; }

.Asset > header > .downloadIcon .fa-cloud-download + i.fa-check {
  display: none; }

.Asset > header > .downloadIcon i.fa-check {
  position: absolute;
  left: 8px;
  bottom: 8px;
  font-size: small; }

.Asset.text > header .backIcon {
  font-size: xx-large; }

.Asset > header figure {
  width: 20%;
  padding-top: 20%;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  /* the following line fixes Safari bug for overflow hidden */
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  display: block;
  margin: auto;
  margin-top: 1rem; }

.Asset > header figure img {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

.Asset.text > header figure {
  display: none; }

.Asset.minimize > header figure {
  float: left;
  margin: 0.25rem; }

.Asset .descriptionSection {
  line-height: 150%;
  text-align: center;
  z-index: 0; }

.Asset.text .descriptionSection {
  margin-top: 3rem;
  font-size: 125%; }

.Asset.minimize .descriptionSection {
  line-height: normal;
  text-align: left;
  padding: 0.4rem; }

.Asset.minimize .descriptionSection header {
  font-size: inherit; }

.Asset .descriptionSection header {
  font-size: x-large; }

.Asset .descriptionSection section {
  /* right padding should be bigger or Arabic text is cut off */
  padding: 1rem 1.5rem 0 1rem;
  white-space: pre-wrap;
  max-height: calc(100vh - env(safe-area-inset-top) - 26rem);
  overflow-y: scroll;
  /*text-align: justify;*/
  /* with justify Arabic is aligned to the left */ }

.Asset.text .descriptionSection section {
  max-height: calc(100vh - env(safe-area-inset-top) - 14rem);
  overflow-x: hidden;
  /* on Chrome horizontal bar sometimes shows up */ }

.Asset .descriptionSection footer dt {
  margin-top: 1rem;
  font-size: small;
  color: #9d9698; }

.Asset.minimize .descriptionSection footer dd {
  color: #888582; }

.Asset #asset-error {
  z-index: 1;
  text-align: center;
  color: darkred;
  visibility: hidden; }

.Asset.text #asset-error {
  display: none; }

.Asset > footer {
  text-align: center; }

.Asset.minimize > footer {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.Asset span.likeIcon, .Asset span.shareIcon {
  margin: 1rem 0.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 4rem;
  border: 0 solid grey;
  position: relative;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  z-index: 1; }

.Asset span.likeIcon.selected {
  background-color: rgba(77, 211, 249, 0.84); }

.Asset span.likeIcon i, .Asset span.shareIcon i {
  color: white;
  font-size: x-large;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0; }

.Asset span.shareIcon img {
  height: 60%;
  cursor: pointer; }

.Asset video {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 100vh;
  object-fit: cover; }

.Asset.minimize video {
  display: none; }


.ImageItem {
  width: calc(50% - 1rem);
  margin: 0 1rem 1rem 0;
  float: left; /* if to use inline-block then extra vertical space happens */
}

.ImageItem:last-child {
  margin-bottom: 10rem;
}

.ImageItem .positioning {
  position: relative;
  padding-top: 140%;
  width: 100%;
}
.ImageItem figure {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: block;
  border-radius: 1rem;
  background-image: linear-gradient(#515858, #b0b8bc, #9db1b9);
  cursor: pointer;
}
.ImageItem figure figcaption header {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-size: 80%;
  padding: 0.2em 0.5em;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.6);
}

.ImageItem figure figcaption .title {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 125%;
  text-align: center;
  padding: 0 0.5rem;
}
.ImageItem figure figcaption footer {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  color: white;
  font-size: 80%;
  text-align: center;
}

.ImageItem figure img {
  display: block;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  filter: brightness(80%);
}
